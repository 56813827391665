
    .top-banner-image {
        background-image: url('../public/images/banner.webp');
        background-size: cover;
        background-position: center;
        height: 400px;
        width: 100%;
        margin: 0% auto;
    }

    .text-overlay {
        transform: translate(0%, 0%);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
    }


   .menu {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #020b4d;
        font-size: 1em;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 10px

    }
    .menu img {
      max-width: 100%;
      max-width: 100px;
      box-shadow: 5px 5px 10px transparent;
      transition: box-shadow 0.3s ease-in-out;
    }

     .menu img:hover {
          box-shadow: 0px 0px 5px rgba(0, 191, 111, 1);
        }

    .menu nav ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0;
        max-width: 100%;
        max-width: 400px;

    }

    .menu nav ul li {
      margin-right: 20%;
    }

    .menu nav a {
      color: #fff;
      text-decoration: none;
      display: block;
    }

    .menu nav button {
          background: none;
          border: none;
          color: blue;
          text-decoration: none;
          cursor: pointer;
          color: #fff;
          font-family: verdana, serif;
          font-size: 0.7em;
    }

      @media only screen and (max-width: 1024px) {

          .menu {
            flex-wrap: wrap;
            justify-content: center;
          }

          .menu img {
            margin-right: 0;
            margin-bottom: 10px;
          }

          .menu nav ul {
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .menu nav ul li {
            margin-right: 0;
            margin-bottom: 10px;
          }
       }