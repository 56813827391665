.App {
  text-align: center;
}

html, body {
        height: 100%;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: center;
        background-color: #FFFFF;
        font-family: verdana, serif;
        color: #0d057d;
        font-size: 1.2em;
    }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

    .body-container {
			max-width: 1200px;
			margin: 0 auto;
			padding: 50px 5%;
			box-sizing: border-box;
			align-items: center;
			justify-content: center;
			height: 100%;
			background-size: cover;
			background-position: center;
		}



    @media screen and (max-width: 480px) {
      body {
        font-size: 0.6em;
      }
    }

    @media screen and (min-width: 768px) {

    }
