        .intro-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr;
            grid-gap: 2px;
        }
        .intro-grid-item {
            background-color: #fff;
            padding: 20px;
            text-align: center;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
        }
        .intro-grid-item img {
            max-width: 100%;
            max-height: 100%;
        }