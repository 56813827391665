
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 2px;
        grid-gap: 2px;
        padding: 2px;
      }
      .grid-item {
        background-color: #fff;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      }
      .grid-item img {
        max-width: 100%;
        max-height: 100%;
        box-shadow: 10px 10px 100px transparent;
        transition: box-shadow 0.3s ease-in-out;
      }

    .grid-item img:hover {
      box-shadow: 0px 0px 10px rgba(0, 0, 255, 0.5);
    }
      .grid-item h2 {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .grid-item p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1em;
        line-height: 1.5;
      }
    @media only screen and (max-width: 768px) {
       body {
            font-size: 1em;
       }

    .grid {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }

    }